//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "ctaimageDefault",
    props: {  
        content: Object
    },
    data: function () { 
        return {
            paintstroke: this.content.showpaintstroke === 'true' ? "paintstroke" : ""
        }
    }
}
